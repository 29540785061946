import store from './../store/index'

export default {
	mine: "个人中心",
	home: "首页",
	server: "服务大厅",
	selectlogin: "登录绑定",
	createCard: "新建就诊卡",
	createChildCard: "新建儿童就诊卡",
	bindCard: "成人绑卡",
	bindChildCard: "儿童绑卡",
	bindSuccess: "绑卡成功",
	createSuccess: "建卡成功",
	introduction: "医院简介",
	detail: "文章详情",
	minePaint: "我的就诊卡",
	minePaintDetail: "详细信息",
	register: "挂号",
	docList: "医生信息",
	selectSecondDepartment: "二级科室",
	heS: "医生信息 ",
	docListTime: "医生排班",
	question: "问卷调查",
	question1: "新冠病毒肺炎初步筛查登记",
	confirm: "确认信息",
	evaluation: "评价医生",
	evalueSuccess: "评价成功",
	detailList: "挂号列表",
	details: "挂号详情",
	success: "挂号成功",
	payList: "缴费列表",
	payDetails: "缴费详情",
  payDetails2: '缴费详情',
	paySuccess: "缴费成功",
	registerList: "挂号记录",
	registerDetail: "挂号记录详情",
	familyDoctor: "家庭医生",
	familyDoctorPerson: "个人基本信息表",
	familyDoctorHealth: "个人基本信息表",
	familyDoctorLive: "个人基本信息表",
	contracted: "家庭医生（团队）签约服务协议书",
	confirmMsg: "预约及确认信息",
	signingRecord: "签约医生记录",
	ordinaryPeople: "一般人服务包",
	children: "儿童服务包",
	pregnantWoman: "孕妇服务包",
	diabetes: "糖尿病服务包",
	hypertension: "高血压服务包",
	aged: "老年人服务包",
	elcsign: "电子签名录入",
	explain: "协议详情",
	reportList: "报告列表",
	// 预约煎药 Start
	medicine: "我的煎药",
	appointmentDetail: "煎药详情",
	batchConfirm: "批量煎药详情",
	confirmDate: "预约取药时间",
	medicineDetail: '已预约煎药详情',
	// 预约煎药 End
	paidDetail: "已缴费详情",
	cheackUpDetail: "检查报告详情",
	cheackOutDetail: "检验报告详情",
	heathlyCodeIndex: "电子健康码列表",
	heathlyCodeSignQr: "电子健康码申领",
	apptRegister: "报到列表",
	cheackPay: "报到缴费",
	cheackSucces: "报到成功",
	calllist: "联系电话",
	dailyList: "每日清单",
	depositDetail: "押金补缴详情",
	depositPay: "押金补缴",
	tisanes: '预约煎药',
	floorGuide: "楼层指引",
	hosGuide: '楼层详情',
	zhuyuanRegistration: '入院登记',
	collectionInfo: '住院登记补录信息',
	zhuyuanSettleList: '医疗费用总清单',
	socialSettleList: '医保结算清单',
	socListDetail: '医保结算清单详情',
	invoiceQuery: '发票查询',
	outHospitalSettlement: '出院结算',
	chatList: "消息列表",
	chatDetail: "消息详情",
	notice:'',
	examinationFee:'体检缴费记录',
	examiPayDetails: '体检待缴费详情',
	exaPaySuccess:'体检缴费成功',
	recordDetails:'体检已缴费详情',
	physicalExaminationReport:'体检报告',
	onlineInsurancePay:'确认支付',
	onlineInsuranceStatus:'医保支付状态确认',
	authFail:'医保授权失败',
	paymentFailList:'微信医保缴费记录',
};

