const server = [
	{
		path: "/server",
		name: "server",
		component: () => import("views/server/index.vue"),
		children: [
			// 预约煎药
			{
				path: "medicine",
				name: "medicine",
				component: () => import("views/server/medicine/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 待预约煎药详情
			{
				path: "appointmentDetail",
				name: "appointmentDetail",
				component: () => import("views/server/medicine/appointmentDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 已预约煎药详情
			{
				path: "medicineDetail",
				name: "medicineDetail",
				component: () => import("views/server/medicine/medicineDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 预约煎药-时间确定页面
			{
				path: "confirmDate",
				name: "confirmDate",
				component: () => import("views/server/medicine/confirmDate.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 批量预约煎药-确认页面
			{
				path: "batchConfirm",
				name: "batchConfirm",
				component: () => import("views/server/medicine/batchConfirm.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				}
			},
			// 楼层指引-首页
			{
				path: "floorGuide",
				name: "floorGuide",
				component: () => import("views/server/floorGuide/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: false,
				},
			},
			// 楼层指引-详情
			{
				path: "hosGuide",
				name: "hosGuide",
				component: () => import("views/server/floorGuide/hosGuide.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: false,
				},
			},
			//挂号
			{
				path: "register",
				name: "register",
				component: () => import("views/server/register/index.vue"),
				// component: () => import('views/server/register/department.vue'),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
				// beforeEnter(to, form, next) {
				// 	let todayStartTime = new Date().getTime()
				// 	let todayEndTime = localStorage.getItem("todayEndTime");
				// 	if (!todayEndTime || todayStartTime > todayEndTime) {
				// 		next({ path: '/server/notice' })
				// 	} else {
				// 		next(true)
				// 	}
				// }
			},
			// 二级科室
			{
				path: "selectSecondDepartment",
				name: "selectSecondDepartment",
				component: () => import("views/server/register/selectSecondDepartment.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//报到
			{
				path: "apptRegister",
				name: "apptRegister",
				component: () => import("views/server/register/apptRegister.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//报到缴费
			{
				path: "cheackPay",
				name: "cheackPay",
				component: () => import("views/server/register/cheackPay.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//报到成功
			{
				path: "cheackSucces",
				name: "cheackSucces",
				component: () => import("views/server/register/cheackSucces.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//医生信息
			{
				path: "docList",
				name: "docList",
				component: () => import("views/server/register/docList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//核酸医生信息
			{
				path: "hesuanDoclist",
				name: "heS",
				component: () => import("views/server/register/hesuanDoclist.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//挂号列表
			{
				path: "doclisttime",
				name: "docListTime",
				component: () => import("views/server/register/docListTime.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//问卷调查
			{
				path: "question",
				name: "question",
				component: () => import("views/server/outpatient/question.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//新冠问卷调查
			{
				path: "question1",
				name: "question1",
				component: () => import("views/server/outpatient/question1.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 确定信息
			{
				path: "/confirm",
				name: "confirm",
				component: () => import("views/server/outpatient/confirm.vue"),
				meta: {
					title: "确认信息",
					child: true,
					showtabbarfalse: true,
					kep: false,
				},
			},
			//问卷调查
			{
				path: "calllist",
				name: "calllist",
				component: () => import("views/server/outpatient/call.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: false,
				},
			},
			//评价医生
			{
				path: "evaluation",
				name: "evaluation",
				component: () => import("views/server/outpatient/evaluation.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true, // ?
				},
			},
			//评价医生成功页
			{
				path: "evalueSuccess",
				name: "evalueSuccess",
				component: () => import("views/server/outpatient/evalueSuccess.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true, // ?
				},
			},
			//挂号列表
			{
				path: "detailList",
				name: "detailList",
				component: () => import("views/server/register/detailList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},

			//挂号详情
			{
				path: "details",
				name: "details",
				component: () => import("views/server/register/details.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//挂号详情
			{
				path: "success",
				name: "success",
				component: () => import("views/server/register/success.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//缴费列表
			{
				path: "payList",
				name: "payList",
				component: () => import("views/server/pay/payList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//缴费详情
			{
				path: "payDetails",
				name: "payDetails",
				component: () => import("views/server/pay/payDetails.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//医保缴费详情
			{
				path: 'payDetails2',
				name: 'payDetails2',
				component: () => import('views/server/pay/payDetails2.vue'),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				}
			},
			//缴费成功页
			{
				path: "paySuccess",
				name: "paySuccess",
				component: () => import("views/server/pay/paySuccess.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//挂号记录列表页
			{
				path: "registerList",
				name: "registerList",
				component: () => import("views/server/register/registerList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//挂号记录详情页
			{
				path: "registerDetail",
				name: "registerDetail",
				component: () => import("views/server/register/registerDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//报告列表
			{
				path: "reportList",
				name: "reportList",
				component: () => import("views/server/report/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			{
				path: "reportDetail",
				name: "reportDetail",
				component: () => import("views/server/report/reportDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			{
				path: "cheackUpDetail",
				name: "cheackUpDetail",
				component: () => import("views/server/report/cheackUpDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			{
				path: "cheackOutDetail",
				name: "cheackOutDetail",
				component: () => import("views/server/report/cheackOutDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//已缴费详情
			{
				path: "paidDetail",
				name: "paidDetail",
				component: () => import("views/server/pay/paidDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//消息列表
			{
				path: "invoiceQuery",
				name: "invoiceQuery",
				component: () => import("views/server/invoiceQuery/invoiceQuery.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//在线问诊消息详情
			{
				path: "chatDetail",
				name: "chatDetail",
				component: () => import("views/server/onlineInquery/chatDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			//在线问诊消息列表
			{
				path: "chatList",
				name: "chatList",
				component: () => import("views/server/onlineInquery/chatList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// //家庭医生
			// {
			//   path: 'familyDoc',
			//   name: 'familyDoc',
			//   component: () => import('views/server/familyDoc/familyDoctor/index.vue'),
			//   meta: {
			//     child: true,
			//     showtabbarfalse: true,
			//     login: true
			//   }
			// },
			// 住院 每日清单
			{
				path: "dailyList",
				name: "dailyList",
				component: () => import("views/server/hospital/dailyList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 押金补缴
			{
				path: "depositPay",
				name: "depositPay",
				component: () => import("views/server/hospital/depositPay/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 押金补缴详情
			{
				path: "depositDetail",
				name: "depositDetail",
				component: () => import("views/server/hospital/depositPay/details.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},

			// 住院 住院登记
			{
				path: "zhuyuanRegistration",
				name: "zhuyuanRegistration",
				component: () => import("views/server/hospital/zhuyuanRegistration/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 住院登记 补录信息
			{
				path: "collectionInfo",
				name: "collectionInfo",
				component: () => import("views/server/hospital/zhuyuanRegistration/collectionInfo.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 住院清单
			{
				path: "zhuyuanSettleList",
				name: "zhuyuanSettleList",
				component: () => import("views/server/hospital/zhuyuanSettleList/zhuyuanSettleList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 医保结算清单
			{
				path: "socialSettleList",
				name: "socialSettleList",
				component: () => import("views/server/hospital/socialSettleList/socialSettleList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 医保结算清单详情
			{
				path: "socListDetail",
				name: "socListDetail",
				component: () => import("views/server/hospital/socialSettleList/socListDetail.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 住院 出院结算
			{
				path: "outHospitalSettlement",
				name: "outHospitalSettlement",
				component: () => import("views/server/hospital/outHospitalSettlement/outHospitalSettlement.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},

			// 就医须知
			{
				path: "notice",
				name: "notice",
				component: () => import("views/server/notice/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 体检缴费
			{
				path: "examinationFee",
				name: "examinationFee",
				component: () => import("views/server/examinationFee/index.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 体检缴费详情
			{
				path: "examiPayDetails",
				name: "examiPayDetails",
				component: () => import("views/server/examinationFee/payDetails.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 体检缴费成功
			{
				path: "exaPaySuccess",
				name: "exaPaySuccess",
				component: () => import("views/server/examinationFee/exaPaySuccess.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 体检已缴费记录
			{
				path: "recordDetails",
				name: "recordDetails",
				component: () => import("views/server/examinationFee/recordDetails.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
			// 体检报告
			{
				path: 'physicalExaminationReport',
				name: 'physicalExaminationReport',
				component: () => import('views/server/physicalExaminationReport/index.vue'),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true
				}
			},
			{
				path: 'onlineInsurancePay',
				name: 'onlineInsurancePay',
				component: () => import('views/server/pay/onlineInsurancePay.vue'),
				meta:{
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			  },
			  {
				path: 'authFail',
				name: 'authFail',
				component: () => import('views/server/pay/authFail.vue'),
				meta:{
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			  },
			  {
				path: 'onlineInsuranceStatus',
				name: 'onlineInsuranceStatus',
				component: () => import('views/server/pay/onlineInsuranceStatus.vue'),
				meta:{
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			  },
			  //医保缴费失败
			{
				path: "paymentFailList",
				name: "paymentFailList",
				component: () => import("views/server/pay/paymentFailList.vue"),
				meta: {
					child: true,
					showtabbarfalse: true,
					login: true,
				},
			},
		],

		meta: {},
	},
	{
		path: "/server/familyDoctor",
		name: "familyDoctor",
		component: () => import("views/server/familyDoc/familyDoctor/index"),
		children: [
			{
				path: "",
				redirect: "/server/familyDoctor/person",
			},
			{
				path: "/server/familyDoctor/person",
				name: "familyDoctorPerson",
				component: () => import("views/server/familyDoc/familyDoctor/person"),
				meta: {
					title: "个人基本信息表",
					showtabbarfalse: true,
					child: true,
				},
			},
			{
				path: "/server/familyDoctor/health",
				name: "familyDoctorHealth",
				component: () => import("views/server/familyDoc/familyDoctor/health"),
				meta: {
					title: "个人基本信息表",
					showtabbarfalse: true,
				},
			},
			{
				path: "/server/familyDoctor/live",
				name: "familyDoctorLive",
				component: () => import("views/server/familyDoc/familyDoctor/live"),
				meta: {
					title: "个人基本信息表",
					showtabbarfalse: true,
				},
			},
			
		],
	},
];

export default server;
